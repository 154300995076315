<template>
  <!--  LOGO AREA  -->

  <img class="mx-auto" src="../assets/Posetype.jpg" />
</template>

<script>
export default {
  name: "Logo_Component",
};
</script>

<style>
</style>