<template>
  <div class="logo-wrapper">
    <div :class="{
      smallLogo: poseTypeRunning,
      'mt-12': !poseTypeRunning,
      'mt-0': poseTypeRunning,
    }" class="logo_container z-50 w-full absolute">
      <div class="logo_wrapper w-[750px] mx-auto">
        <Logo_Component />
      </div>
    </div>
  </div>

  <div class="video-wrapper">
    <div class="z-50 fixed bottom-6 right-6">
      <div class="
          w-[240px]
          h-[180px]
          bg-neutral-900
          shadow-[8px_10px_0_0_rgb(0,0,0)]
        ">
        <div class="absolute w-full h-full flex items-center justify-center">
          <p class="text-neutral-100 text-center">
            please activate<br />your webcam
          </p>
        </div>

        <video id="video" class="grayscale -scale-x-100"></video>
      </div>
    </div>
  </div>

  <Transition name="bg-fade">
    <div v-if="show" class="
        z-40
        absolute
        flex
        justify-center
        items-center
        w-full
        h-full
        bg-white
      ">
      <div class="mb-24">
        <div class="w-[700px] mx-auto text-center">
          <div class="mt-56 text-xl">
            <div class="mb-4">
              <p>
                This is a a playful study case on Variable Font Technology and
                User Interaction. Make sure that your hands are visible on video
                if you stretch your arms to the side.
              </p>
            </div>
            <div class="mb-4">
              <p>
                If you are ready press the button. Move your hands and head
                around and try to manipulate the visible glyph.
              </p>
            </div>
            <div class="text-sm text-neutral-500">
              <p>
                This is just a technial demo! More information about the project
                <a class="underline underline-offset-3" href="https://www.kressmichael.de/projects/posetype/">found
                  here</a>.<br />Concept and Design by Michael Kreß and Erik Seitz. Coding by Michael Kreß.<br>Version:
                0.3
              </p>
            </div>
          </div>
          <div class="mt-12 mb-5 w-fit mx-auto">
            <div class="relative">
              <div class="
                block
                absolute
                -inset-0
                -z-50              
                bg-black
                translate-x-[8px]
                translate-y-[10px]">
              </div>
              <button @click="setStates" class="
                relative                                
                bg-white
                z-50     
                mx-auto
                px-8
                py-3
                text-xl
                border-[3px] border-current                
                hover:translate-x-[4px]
                hover:translate-y-[5px]
              ">
                LET’S START
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>

  <div class="flex flex-col h-screen max-h-screen">
    <!--  GAME AREA  -->
    <GlyphArea />

    <div>
      <p class="fixed bottom-6 w-full text-center fluid-sm text-neutral-400">
        This is just a technial demo! Expect things to break and behave glitchy.<br>More information about the project
        <a class="underline underline-offset-3" href="https://www.kressmichael.de/projects/posetype/">found
          on kressmichael.de/posetype</a>.<br />Idea by Michael Kreß and Erik Seitz. Coding by Michael Kreß.<br>Version: 0.3.
      </p>
    </div>
  </div>
  <div class="fixed top-5 left-3">
    <div class="
      block
      absolute
      -inset-0
      -z-50              
      bg-black
      translate-x-[4px]
      translate-y-[5px]">
    </div>
    <button v-if="!fullscreen" @click="toggleFullScreen" class="
      relative
      bg-white
      px-2
      py-2
      text-s
      font-medium
      border-[2px] border-current
      hover:translate-x-[2px]
      hover:translate-y-[2.5px]
  "><svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-215.000000, -257.000000)"><g id="fullscreen" transform="translate(215.000000, 257.000000)"><path d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z" id="Shape"/></g></g></g></svg></button>
  </div>
  <div class="fixed top-5 left-16">
    <div class="
      block
      absolute
      -inset-0
      -z-50              
      bg-black
      translate-x-[4px]
      translate-y-[5px]">
    </div>
    <button @click="restart" class="
      relative
      bg-white
      px-2
      py-2
      text-s
      font-medium
      border-[2px] border-current
      hover:translate-x-[2px]
      hover:translate-y-[2.5px]
  "><svg class="svg-icon" style="width: 1.05859375em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1084 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M788.804292 977.500541a503.543363 503.543363 0 0 0 162.507176-119.862595 509.687074 509.687074 0 0 0 103.780528-174.312738 515.047762 515.047762 0 0 0 28.550186-194.068985 514.385205 514.385205 0 0 0-45.53574-190.816432 508.663122 508.663122 0 0 0-118.657945-164.193685A501.676156 501.676156 0 0 0 554.921651 0.530046 503.904757 503.904757 0 0 0 203.585714 166.530704a509.807538 509.807538 0 0 0-94.564962 150.159522L0 290.187944l45.174345 154.014399 45.234577 154.0144 109.382147-116.610042L309.173215 365.056891l-103.057738-24.936238a411.387699 411.387699 0 0 1 69.990118-106.611453 405.846313 405.846313 0 0 1 576.906499-25.960191c166.181356 153.592772 177.625524 415.001647 25.659027 582.869512a406.087243 406.087243 0 0 1-577.026963 25.899958l-66.315938 73.302904a502.097784 502.097784 0 0 0 364.707543 133.836525 499.387323 499.387323 0 0 0 188.768529-45.957367z"  /></svg></button>
  </div>
</template>

<script>
import GlyphArea from "./components/GlyphArea.vue";
import Logo_Component from "./components/Logo.vue";
import { newGlyph } from "./main";

export default {
  data() {
    return {
      show: true,
      poseTypeRunning: false,
      fullscreen: false,
    }
  },
  methods: {
    setStates() {
      this.show = !this.show;
      this.poseTypeRunning = !this.poseTypeRunning;
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    exitFullscreen() {
      document.exitFullscreen();
    },

    updateFullScreenState() {
      this.fullscreen = Boolean(document.fullscreenElement);
    },

    created() {
      this.updateFullScreenState();
    },

    destroyed() {
      document.removeEventListener("fullscreenchange", this.updateFullScreenState);
    },

    restart: newGlyph

  },
  name: "App",
  components: {
    GlyphArea,
    Logo_Component,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-fade-enter-active,
.bg-fade-leave-active {
  transition: opacity 300ms ease-in-out;
}

.bg-fade-enter-from,
.bg-fade-leave-to {
  opacity: 0%;
}

.smallLogo {
  transition: all ease-in-out 800ms;
  transform: scale(0.5) translateY(-35%);
}
</style>
