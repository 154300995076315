/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";

import * as poseDetection from "@tensorflow-models/pose-detection";
import * as math from "mathjs";

// import * as tf from "@tensorflow/tfjs-core";
// Register one of the TF.js backends.
import "@tensorflow/tfjs-backend-webgl";

import "./assets/tailwind.css";
import { DETECTOR_CONFIG, VIDEO_CONFIG, THRESHOLD, KEYS } from "./parameters";

let detector, video, holdingPoseTimer, endPtAONE, endPtATWO;
let activeGlyph, bgGlyph, allGlyphs;
let oldValueOne, oldValueTwo;

allGlyphs = ["a", "b", "d", "e", "r", "v"]

async function startWebcam() {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    throw new Error(
      "Browser API navigator.mediaDevices.getUserMedia not available"
    );
  }

  video = document.getElementById("video");

  const stream = await navigator.mediaDevices.getUserMedia(VIDEO_CONFIG);

  video.srcObject = stream;
  video.play();
}

async function initMoveNet() {
  return await poseDetection.createDetector(
    poseDetection.SupportedModels.MoveNet,
    DETECTOR_CONFIG
  );
}

function createCSSSettings(nameOne, valueOne, nameTwo, valueTwo) {
  // Set up the axes Settings to be put into the CSS-Property
  return `"${nameOne}" ${valueOne}, "${nameTwo}" ${valueTwo}`;
}

function between(x, min, max) {
  return x >= min && x <= max;
}

function checkValues(valueOne, valueTwo) {
  // Check if the current user values
  // are within the range of the end points +- threshold
  let conditionOne = false;
  let conditionTwo = false;

  if (between(valueOne, endPtAONE - THRESHOLD, endPtAONE + THRESHOLD)) {
    conditionOne = true;
  }
  if (between(valueTwo, endPtATWO - THRESHOLD, endPtATWO + THRESHOLD)) {
    conditionTwo = true;
  }
  if (conditionOne && conditionTwo) {
    return true;
  } else {
    return false;
  }
}

async function getPoses() {  
  if (video.readyState < 2) {
    await new Promise((resolve) => {
      video.onloadeddata = () => {
        resolve(video);
      };
    });
  }

  let poses = null;

  if (detector != null) {
    try {
      poses = await detector.estimatePoses(video);
    } catch (error) {
      detector.dispose();
      detector = null;
      alert(error);
    }
  }
  
  if (poses && poses.length > 0) {
    let normalizedKeypoints =
      poseDetection.calculators.keypointsToNormalizedKeypoints(
        poses[0].keypoints,
        { width: VIDEO_CONFIG.video.width, height: VIDEO_CONFIG.video.height }
      );

// Maximal reale Spanweite der Hände pro Frame basierend auf der Körpergröße (sehr grobe Schätzung)
    // 1 > Wert > 0
    let maxDistHands =      
      math.distance(
        [
          normalizedKeypoints[KEYS.LEFT_HIP].x,
          normalizedKeypoints[KEYS.LEFT_HIP].y,
        ],
        [
          normalizedKeypoints[KEYS.RIGHT_SHOULDER].x,
          normalizedKeypoints[KEYS.RIGHT_SHOULDER].y,
        ]
      );

    // Distanz der Hände in Abhängigkeit von der Videogröße
    // 1 > Distanz > 0
    // Maximale Distanz der Hände ist die Diagonale (Hypotenuse) eines rechtwinkligen Dreiecks mit den Seitenlänge a = b = 1, c = 1.4142
    let d =  math.distance(
      [
        normalizedKeypoints[KEYS.LEFT_WRIST].x,
        normalizedKeypoints[KEYS.LEFT_WRIST].y,
      ],
      [
        normalizedKeypoints[KEYS.RIGHT_WRIST].x,
        normalizedKeypoints[KEYS.RIGHT_WRIST].y,
      ]
    );
    
    d = d * 1 / maxDistHands;

    let currValueOne =  d;
    // let currValueTwo = normalizedKeypoints[KEYS.LEFT_WRIST].y / 2 + 0.25;
    
    // What the heck? Was ist das?
    // currValueTwo = (currValueTwo - 0.3) * ( 1 / (0.7 - 0.3) ) 

    // let currValueTwo = math.distance(
    //   [
    //     normalizedKeypoints[KEYS.NOSE].x,
    //     normalizedKeypoints[KEYS.NOSE].y
    //   ],
    //   [
    //     normalizedKeypoints[KEYS.RIGHT_WRIST].x,
    //     normalizedKeypoints[KEYS.RIGHT_WRIST].y
    //   ]
    // ) * 1 / maxDistHands;
    
    let currValueTwo = normalizedKeypoints[KEYS.LEFT_WRIST].x * normalizedKeypoints[KEYS.RIGHT_WRIST].x;

    // Easing zwischen neuem und altem Wert
    // klappt das so überhaupt? Ahhh!
    currValueOne = currValueOne - (currValueOne - oldValueOne) * 0.75;
    currValueTwo = currValueTwo - (currValueTwo - oldValueTwo) * 0.75;

    let _currValueOne = currValueOne * 1000.0;  
    let _currValueTwo = currValueTwo * 1000.0;

    let varFontSettings = createCSSSettings(
      "AONE",
      _currValueOne,
      "ATWO",
      _currValueTwo
    );

    activeGlyph.style.setProperty("font-variation-settings", varFontSettings);        

    if (checkValues(_currValueOne, _currValueTwo ) ) {
      if (holdingPoseTimer > 60) {
        newGlyph();
        return;
      }
      // console.log(holdingPoseTimer);
      holdingPoseTimer += 1;
    }
    oldValueOne = currValueOne;
    oldValueTwo = currValueTwo;
  }

  requestAnimationFrame(getPoses);
}


export async function newGlyph() {
  holdingPoseTimer = 0;

  let randomGlyph = allGlyphs[Math.floor(Math.random() * allGlyphs.length)];    

  activeGlyph.innerHTML = randomGlyph;
  bgGlyph.innerHTML = randomGlyph;  

  endPtAONE = math.random(0, 1000);
  endPtATWO = math.random(0, 1000);

  let varFontSettings = createCSSSettings("AONE", endPtAONE, "ATWO", endPtATWO);

  bgGlyph.style.setProperty("font-variation-settings", varFontSettings);

  oldValueOne = null;
  oldValueTwo = null;

  getPoses();
}

async function posetype() {
  activeGlyph = document.getElementById("active-glyph");
  bgGlyph = document.getElementById("bg-glyph");  

  await startWebcam();

  detector = await initMoveNet(DETECTOR_CONFIG);

  newGlyph();
}

createApp(App).mount("#app");

posetype();
