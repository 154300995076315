<template>
  <div
    id="glyphArea"
    class="grow flex items-center justify-center w-fill fluid-9xl pb-[50px]"
  >
    <div class="text-center">
      <p id="active-glyph" class="absolute text-red-600 mix-blend-multiply">
        a
      </p>
      <p id="bg-glyph" class="bg-glyph text-sky-500">a</p>
    </div>
    <!-- <input type="text" placeholder="g" class="w-full text-center" /> -->
  </div>
</template>

<script>
export default {
  name: "GlyphArea",
};
</script>

<style scoped>
@font-face {
  font-family: "Font";
  src: url("../assets/fonts/PoseTypeFont.ttf");
}

#glyphArea {
  font-family: "Font";
  font-variation-settings: "AONE" 500, "ATWO" 500;
}

#bg-glyph {
  font-variation-settings: "AONE" 500, "ATWO" 500;
  }


:focus-visible {
  outline: none;
}
</style>